import React, { useEffect, useRef, useState } from 'react'

import debounce from 'lodash.debounce'
import classNames from 'classnames'
import DefaultLayout from '../../layout/default'
import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/prices.module.scss'
import { useLocalizationContext } from '../../localization/useLocalizationContext'
import { externalLinks } from '../../pages-data/_V2/common/links'
import DetailFeatures from '../../pagesSections/prices/DetailFeatures'
import { getData } from '../../components/_V2/prices/Tariffs/data'
import Tariffs from '../../components/_V2/prices/Tariffs'

export default function PagePriceCompare() {
  const metaTags = [
    {
      name: 'description',
      content: 'Сравните тарифные планы на Quick Resto - систему автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги - автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат.',
    },
    // {
    //   name: 'keywords',
    //   content: 'автоматизация кафе ресторанов под ключ онлайн-касса тарифы на подписку',
    // },
    {
      property: 'og:url',
      content: 'https://quickresto.ru/price/compare/',
    },
    {
      property: 'og:title',
      content: 'Сравнение тарифных планов Quick Resto | Автоматизация под ключ',
    },
    {
      property: 'og:description',
      content: 'Сравните тарифные планы на Quick Resto - систему автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги - автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ]

  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Сравнение тарифных планов Quick Resto | Quick Resto',
    'ru-KZ': 'Сравнение тарифных планов Quick Resto | Quick Resto Казахстан',
    'kz-KZ': 'Сравнение тарифных планов Quick Resto | Quick Resto Казахстан',
    'ru-BY': 'Сравнение тарифных планов Quick Resto | Quick Resto Беларусь',
  }
  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}price/compare/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}price/compare/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}price/compare/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}price/compare/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Quick Resto - доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги - автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру 8 (800) 500-21-38 или пишите на почту sales@quickresto.ru.',
    'ru-KZ': 'Quick Resto — доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги — автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру +7 (727) 312-31-99 или пишите на почту qrsib@yandex.ru. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Quick Resto — доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги — автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру +7 (727) 312-31-99 или пишите на почту qrsib@yandex.ru. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Quick Resto — доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги — автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру +375-44-7-937-937 или пишите на почту info@quickresto.by. Автоматизируй бизнес в Беларуси',
  }
  const MODULES_COST_INITIAL = {
    cashier: {
      month: 0,
      month6: 0,
      year: 0,
      yearOld: 0,
    },
    smart: {
      month: 0,
      month6: 0,
      year: 0,
      yearOld: 0,
    },
    pro: {
      month: 0,
      month6: 0,
      year: 0,
      yearOld: 0,
    },
  }

  const [mobileSelectedTariff, setMobileSelectedTariff] = useState('web')
  const [currentPeriod, setCurrentPeriod] = useState('month')
  const [isScrolled, setIsScrolled] = useState(false)
  const [isScrolledBelowTariffs, setIsScrolledBelowTariffs] = useState(false)
  const currentPeriodData = getData(localizationContext.locale).periods.find((period) => period.name === currentPeriod)
  const [modulesCost, setModulesCostState] = useState(JSON.parse(JSON.stringify(MODULES_COST_INITIAL)))

  const featuresEl = useRef(null)
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 130)

    setIsScrolledBelowTariffs(
      featuresEl.current?.getBoundingClientRect().top < 300,
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll))

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
        pageStyles.pageWrapper,
        pageStyles.pageGray,
        styles.prices,
      )}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
    >
      <div className={styles.prices__backdrop} />

      <div className={styles.prices__headline}>
        <h1 className={styles.prices__title}>
          Сравните тарифные планы
        </h1>
      </div>
      <div
          className={classNames(
              styles.prices__tariffsContainer,
              pageStyles.pageSection,
          )}
          >
      <div
        className={classNames(
          pageStyles.pageSection,
          styles.prices__stickyContainer,
        )}
      >
        <Tariffs
          className={classNames(
            styles.prices__sticky,
            isScrolled && styles.prices__roundedNone,
            {
              [styles.prices__static]: !isScrolledBelowTariffs,
              [styles.prices__staticScrolled]: isScrolledBelowTariffs,
            },
          )}
          scrolledBelowTariffs={isScrolledBelowTariffs}
          setMobileSelectedTariff={setMobileSelectedTariff}
          setCurrentPeriod={setCurrentPeriod}
          currentPeriod={currentPeriod}
          modulesCost={modulesCost}
        />
      </div>
      <div
        ref={featuresEl}
        className={classNames(
          pageStyles.pageSection,
          styles.prices__featuresWrapper,
        )}
      >
        <DetailFeatures
          mobileSelectedTariff={mobileSelectedTariff}
          notShowButton={true}
          className={pageStyles.pageSection}
        />
      </div>
      </div>
    </DefaultLayout>
  )
}
